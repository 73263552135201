@import '../../../../styles/helpers'

.wrapper
  padding: 30px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: transparent
  color: white

.primary
  font-size: 48px
  letter-spacing: -1px
  line-height: 56px
  margin-bottom: 20px
  color: rgb(77,77,77)
  text-align: center
  +s
    font-size: 36px
    line-height: 40px
.secondary
  font-size: 24px
  line-height: 32px
  margin-bottom: 20px
  color: white
  font-weight: 400 !important
  text-align: center
  +s
    font-size: 18px
    line-height: 25px

.items
  display: flex
  flex-direction: row
  margin-top: 20px
  +s
    justify-content: center
    margin-top: 10px

  .item
    &:not(:last-child)
      margin-right: 20px
    img
      height: 40px
      width: 40px

