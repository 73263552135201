.landingWrap
  background-color: transparent
  //animation: gradient 7.5s ease infinite
  box-shadow: inset 0px 0px 10px 1px rgba(22,22,22,.1)
  object-fit: cover
  background-image: url("https://cdn.discordapp.com/attachments/946518344973025341/963840020689592330/website_background_correct_25.png")
  position: relative
  background-repeat: no-repeat
  z-index: 1
  background-blend-mode: color-dodge
  background-size: cover

.landingContent
  max-width: 1400px
  margin-right: auto
  margin-left: auto
  z-index: 5




@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%

