// common styles 
@import ./reset
@import ./helpers
@import ./common
@import '~antd/dist/antd.css'

// global classes
@import ./blocks/section
@import ./blocks/container
@import ./blocks/button
@import ./blocks/title
@import ./blocks/slick
@import ./blocks/status

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"])
  display: none