.container
    width: 90%
    max-width: 1700px
    margin: 0 auto
    padding: 0 80px
    +t
        padding: 0 40px
    +m
        padding: 0 32px
    +s
        padding: 10px
    