.Toastify__toast--success {
    border-left: 8px solid #45B26B  !important;
    border-radius: 8px !important;
    background: #F4F5F6
}

.Toastify__toast--success::before {
    content: url("./check.svg");
    position:relative; 
    z-index:100000;
    top:10px;
    width: 20px;
    height: 20px;
}

.Toastify__toast--error {
    border-left: 8px solid #F44336  !important;
    border-radius: 8px !important;
    background: #F4F5F6
}

.Toastify__toast--error::before {
    content: url("./cancel.svg");
    position:relative; 
    z-index:100000;
    top:10px;
    width: 20px;
    height: 20px;
}

.Toastify__toast--info {
    border-left: 8px solid #3772FF  !important;
    border-radius: 8px !important;
    background: #F4F5F6
}

.Toastify__toast--info::before {
    content: url("./info.svg");
    position:relative; 
    z-index:100000;
    top:10px;
    width: 20px;
    height: 20px;
}

.Toastify__toast--default {
    border-left: 8px solid #3772FF  !important;
    border-radius: 8px !important;
    background: #F4F5F6
}

.Toastify__toast--default > div > div:nth-child(1) > div {
    margin-left: 5px;
}

.Toastify__toast--default > div > div:nth-child(2) {
    padding-left: 15px;
}
/* .Toastify__toast--info::before {
    content: url("./info.svg");
    position:relative; 
    z-index:100000;
    top:10px;
    width: 20px;
    height: 20px;
} */

.Toastify__toast > div > div  {
    width: 0px !important;
}

.Toastify__toast > div > div > svg {
    display: none;
}