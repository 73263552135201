@import './../../../../styles/helpers'


.heroWrapper
  display: flex
  flex-direction: row
  width: 100%
  padding-left: 80px
  padding-right: 80px
  padding-top: 100px
  padding-bottom: 100px
  background-size: 400% 400%
  font-weight: 400
  text-align: left
  +d
    justify-content: center
  +t
    flex-direction: column
    padding-top: 0px
    align-items: center
  +s
    padding: 30px
    padding-top: 40px

.heroRight
  width: 50%
  max-width: 600px
  margin-right: 50px
  order: 1
  margin-top: 30px
  +d
    width: 100%
    margin-left: 0px
    margin-right: 0px
    margin-top: 0px
    margin-bottom: 20px
  +t
    order: 2
    margin-top: 50px

.heroLeft
  order: 2
  max-width: 600px
  //box-shadow: 0px 0px 100px 10px rgba(222, 222, 222, 1)
  background: none
  overflow: hidden
  margin-left: 50px
  +d
    max-width: 400px
  +t
    order: 1
    margin-left: 0px


  img
    width: 100%
    min-width: 100%
    overflow: hidden
    border-radius: 10px

.heroPrimary
  font-size: 48px
  letter-spacing: -1px
  line-height: 56px
  margin-bottom: 20px
  color: rgba(77, 77, 77)
  font-weight: 700
  text-align: left
  +d
    text-align: left
  +t
    text-align: center
  +s
    font-size: 40px
    line-height: 40px

.heroSecondary
  font-size: 22px
  line-height: 27px
  margin-bottom: 20px
  color: rgba(77, 77, 77)
  font-weight: 400 !important
  text-align: left
  letter-spacing: .05px
  +d
    text-align: left
  +t
    text-align: center
  +s
    font-size: 20px
    line-height: 25px

.heroBtns
  display: flex
  flex-direction: row
  justify-content: start
  +d
    justify-content: left
  +t
    justify-content: center

.heroBtn
  padding: 10px
  font-size: 20px
  padding-top: 5px
  padding-bottom: 5px
  background-color: transparent
  border: 2px solid rgba(77, 77, 77)
  box-shadow: 1px 2px 25px rgba(100, 100, 100, .1)
  border-radius: 5px
  color: rgba(77, 77, 77)
  font-weight: bolder
  margin-top: 5px




