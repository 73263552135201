@import './../../../../styles/helpers'

.wrapper
  display: flex
  flex-direction: column
  width: 100%
  color: rgba(77,77,77)
  padding: 80px
  padding-top: 80px
  padding-bottom: 60px
  +s
    padding: 20px

.primary
  font-size: 40px
  text-align: center
.secondary
  font-size: 24px
  margin-top: 0px
  font-weight: 400
  text-align: center
  margin-bottom: 20px


.featureItems
  display: flex
  flex-direction: row
  width: 100%
  margin-left: auto
  margin-right: auto
  justify-content: center
  border-radius: 5px
  flex-wrap: wrap
  gap: 30px
  margin-top: 30px
.featureItem
  width: 30%
  +t
    width: 40%
  +m
    width: 100%
    max-width: 400px
  color: rgba(77,77,77)
  text-align: center
  background-color: white
  box-shadow: 2px 2px 10px rgba(77,77,77,.2)
  padding: 30px
  border-radius: 10px

  .title
    font-size: 30px

  img
    width: 50px
    height: 50px
    margin-bottom: 10px


.description
  margin-top: 5px
  font-size: 16px
  font-weight: 400

.flexWrap
  display: flex
  margin-right: auto
  margin-left: auto
  justify-content: center