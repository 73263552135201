@import helpers

.slick-arrow
    +s
      width: 0px !important
      overflow: hidden
    .svg
      +s
        width: 0px !important

button
  .slick-arrow
    +s
      width: 0px !important

.slick-slide:not(.slick-active)
  pointer-events: none

a
  &:hover
    color: $neutrals1

