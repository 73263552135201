@import '../../styles/helpers'

.searchInputs
  margin-top: 105px
  display: flex


.search input 
  background-color: rgba(255,255,255,0.75)
  border: 1px solid
  border-color: $neutrals6
  font-size: 14px
  padding: 16.5px
  height: 44px
  width: 100%
  border-radius: 10px
  +m
    height: 50px
    font-size: 15px


.searchIcon 
  height: 60px
  width: 50px
  background-color: white
  display: grid
  place-items: center


input:focus 
  outline: none

.searchIcon svg 
  font-size: 35px


.dataResult 
  margin-top: 5px
  max-height: 300px
  background-color: white
  border-radius: 10px
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 25px
  overflow: hidden
  overflow-y: auto
  position: absolute
  z-index: 999999 !important
  top: 62px
  padding-bottom: 20px
  width: 450px
  +y
    width: 350px
  +x
    width: 320px
  +d
    width: 350px
  +m
    position: relative
    top:  0px
    width: calc(100%)



.noResult
  margin-top: 5px
  width: 500px
  height: 50px
  display: flex
  background-color: white
  border-radius: 10px
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 25px
  overflow: hidden
  position: absolute
  z-index: 99 !important
  top: 62px
  font-size: 15px
  margin-bottom: auto
  +m
    position: relative
    top:  0px
    width: calc(100%)



.dataResult::-webkit-scrollbar 
  display: none


.dataResult .dataItem 
  width: 100%
  height: 50px
  display: flex
  align-items: center
  color: black
  position: absolute

.dataItem p 
  margin-left: 10px

a 
  text-decoration: none

.sectionHeader
  padding-left: 20px
  margin-top: 15px
  padding-bottom: 10px
  color: $neutrals3
  font-weight: bolder
  font-size: 16px
  border-bottom: 1px solid $neutrals6

.eventResults
  display: flex
  flex-direction: column



#clearBtn 
  cursor: pointer

.eventResult
  font-weight: 600
  display: flex
  flex-direction: row
  position: relative
  padding-top: 12.5px
  padding-bottom: 12.5px
  border-bottom: 1px solid $neutrals6
  align-items: center
  transition: .1s ease-in-out
  z-index: 9999999
  &:first-child
    border-top: 1px solid $neutrals6
  &:hover
    transform: scale(1.005)
    cursor: pointer


.eventPfp
  border-radius: 100%
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3772ff, 0px 5px 5px rgb(77 77 77 / 77%)
  width: 30px
  height: 30px
  margin-left: 20px
  margin-right: 10px
  object-fit: cover


.eventTitle
  font-size: 14px
  color: $neutrals2
  +m
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 50%

.attending
  margin-left: auto
  margin-right: 10px
  font-size: 11px
  height: 24px
  line-height: 24px

.noResults
  margin-left: 20px
  margin-top: -5px

.noResultText
  margin-top: auto
  margin-bottom: auto
  margin-left: 20px

