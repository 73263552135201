[class^="status"]
    display: inline-block
    padding: 0 8px
    border-radius: 4px
    font-size: 12px
    line-height: 26px
    font-weight: 700
    text-transform: uppercase
    color: $neutrals8

.status-green
    background: $green

.status-purple
    background: $purple

.status-black
    background: $neutrals2

.status-stroke-black
    box-shadow: inset 0 0 0 2px $neutrals6
    color: $neutrals4

.status-stroke-green
    box-shadow: inset 0 0 0 2px $green
    color: $green

