@import './../../../../styles/helpers'


.heroWrapper
  display: flex
  flex-direction: row
  width: 100%
  padding-left: 80px
  padding-right: 80px
  padding-top: 100px
  padding-bottom: 0px
  background-size: 400% 400%

  +t
    flex-direction: column
    padding-bottom: 70px
  +s
    padding: 30px
    padding-top: 70px

  animation: gradient 7.5s ease infinite
.heroRight
  width: 50%
  max-width: 400px
  margin-left: 150px
  margin-right: auto
  +t
    width: 100%
    margin-left: 0px
    order: 1
    display: none
.heroLeft
  width: 50%
  +t
    order: 2
    width: 100%
    text-align: center !important

.heroPrimary
  font-size: 48px
  letter-spacing: -1px
  line-height: 56px
  margin-bottom: 20px
  color: rgb(77,77,77)
  +s
    font-size: 40px
    line-height: 40px
.heroSecondary
  font-size: 24px
  line-height: 32px
  margin-bottom: 20px
  color: rgb(77,77,77)
  font-weight: 400 !important
  +s
    font-size: 20px
    line-height: 25px

.heroBtns
  display: flex
  flex-direction: row
  justify-content: left
  line-height: 30px
  +t
    justify-content: center
  +s
    justify-content: center

.heroBtn
  padding: 10px
  font-size: 14px
  background-color: transparent
  border: 2px solid rgb(77,77,77)
  box-shadow: 1px 2px 10px rgba(77,77,77,.3)
  border-radius: 5px
  color: rgb(77,77,77)
  font-weight: bolder
  &:not(:last-child)
    margin-right: 20px

.items
  display: flex
  flex-direction: row
  margin-top: 20px
  +t
    justify-content: center
    margin-top: 10px

  .item
    &:not(:last-child)
      margin-right: 20px
    img
      height: 35px
      width: 35px

