.loginWrap
  display: flex !important
  justify-content: center
  flex-direction: column
  background: #FFFFFF
  border-radius: 10px
  box-shadow: 1px 2px 15px rgba(77,77,77,.5)
  text-align: center !important
  z-index: 3 !important
  width: 100%
  max-width: 100%
  height: 100%
  padding: 20px

.loginField
  margin-top: 20px

.submitBtn
  margin-top: 20px

.createAccount
  text-align: center
  font-size: 22px
  color: #575d63
