@import ../../styles/helpers

.header
    position: relative
    z-index: 20
    padding: 20px 0

    border-bottom: 1px solid $neutrals6
    background-color2: $neutrals8
    .home
        padding-right: 60px
        padding-left: 0px
        +x
            padding-left: 60px
        +t
            padding-right: 20px
            padding-left: 20px

    +x
        padding-left: 0px
    +t
        padding: 20px 0
    +m
        padding: 30px 0 24px
        border: none
        
.container
    display: flex
    align-items: center
    padding-left: 20px
    padding-right: 20px
    max-width: 1240px
    margin-left: auto
    margin-right: auto
    padding-left: 0px
    &.docs
        max-width: 100% !important
    +x
        padding-left: 0px
        padding-right: 0px
    +t
        margin-left: 0px
        margin-right: 0px
        width: 100%
        padding-left: 20px
        padding-right: 20px
    +s
        padding: 20px !important
        padding-bottom: 0px !important
        padding-top: 0px !important
    & > .button
        margin-right: 12px
        +m
            display: none

.logo
    position: relative
    z-index: 12
    display: inline-block
    flex-shrink: 0
    width: 128px
    margin-right: 32px
    img
        width: 100%
    
.wrapper
    display: flex
    align-items: center
    flex-grow: 1
    +m
        position: absolute
        top: 0
        left: 0
        right: 0
        flex-direction: column
        align-items: stretch
        height: 120vh
        margin: 0
        padding: 100px 32px 0px
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2)
        background: $neutrals8
        visibility: hidden
        opacity: 0
        transition: all .2s
        &.active
            visibility: visible
            opacity: 1
    .button
        display: none
        +m
            display: inline-flex
            margin-top: 16px
            font-size: 18px

.nav
    display: flex
    margin-right: auto
    border-left: 2px solid $neutrals6
    +m
        display: flex
        flex-direction: column
        margin-top: 10px
        border: none

.link
    margin-left: 32px
    font-weight: 500
    +dm-sans
    line-height: 40px
    color: $neutrals4
    transition: color .2s
    font-size: 15px
    +z
        font-size: 14px
    +m
        margin-left: 0
        font-size: 22px
        line-height: 64px

    &:hover,
    &.active
        color: $blue
    &:nth-child(n+4)
        display: none
        +m
            display: block
.hidetablet
    +w
        display: none
    +m
        display: block
.search
    position: relative
    flex-shrink: 0
    width: 450px
    margin-right: 24px
    +y
        width: 350px
    +x
        width: 320px
    +d
        width: 350px
    +m
        display: block
        width: 100%
        margin: auto 0 0
        margin-bottom: 10px
        margin-top: 10px


.input
    width: 100%
    height: 44px
    padding: 0 42px 0 16px
    background: none
    border-radius: 8px
    border: 1px solid $neutrals6

    +poppins
    +caption-2
    transition: border-color .2s
    +placeholder
        color: $neutrals4
    &:focus
        border-color: $neutrals5


.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 42px
    svg
        fill: $neutrals4
        transition: fill .2s
    &:hover
        svg
            fill: $blue

.notification
    margin-right: 24px
    +m
        margin-right: 20px

.burger
    display: none
    +m
        display: block
        position: relative
        width: 32px
        height: 32px
        background: none
        margin-left: auto
        -webkit-tap-highlight-color: rgba(0,0,0,0)
        &.active
            &:before
                transform: translateY(0) rotate(-45deg)
            &:after
                transform: translateY(0) rotate(45deg)
        &:before,
        &:after
            content: ''
            position: absolute
            top: 16px
            left: 6px
            width: 20px
            height: 2px
            background: $neutrals4
            border-radius: 2px
            transition: transform .2s
        &:before
            transform: translateY(-4px)
        &:after
            transform: translateY(3px)

.hide
    display: none !important