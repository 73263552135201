@import './../../../../styles/helpers'
.wrapper
  display: flex
  flex-direction: column
  width: 100%
  color: rgba(77,77,77)
  padding: 80px
  padding-top: 0px
  +s
    padding: 20px

.primary
  font-size: 48px
  padding-top: 30px
  letter-spacing: -1px
  line-height: 56px
  margin-bottom: 20px
  font-weight: 700
  text-align: center
  +s
    font-size: 40px
    line-height: 40px
.secondary
  font-size: 24px
  line-height: 32px
  margin-bottom: 20px
  font-weight: 400 !important
  text-align: center
  +s
    font-size: 20px
    line-height: 25px


.pricingItems
  display: flex
  flex-direction: row
  padding-top: 40px
  padding-bottom: 40px
  padding-left: 20px
  padding-right: 20px
  width: 100%
  background-color: white
  box-shadow: 2px 2px 10px rgba(77,77,77,0.1)
  margin-left: auto
  margin-right: auto
  justify-content: center
  border-radius: 5px
  margin-top: 30px
  gap: 20px
  +t
    flex-direction: column
    width: auto
    padding: 80px
    padding-top: 40px
    padding-bottom: 40px
    gap: 40px
  +s
    padding: 30px
    width: 100%


.pricingItem
  width: 33%
  color: rgba(77,77,77)
  text-align: center
  +t
    width: 100%
  &:nth-child(2)
    border-right: 2px solid rgba(200,200,200,.4)
    border-left: 2px solid rgba(200,200,200,.4)
    +t
      border-right: 0px solid rgba(200,200,200,.4)
      border-left: 0px solid rgba(200,200,200,.4)
      border-bottom: 2px solid rgba(200,200,200,.4)
      border-top: 2px solid rgba(200,200,200,.4)
      padding-bottom: 20px
      padding-top: 20px
      margin-left: -30px
      margin-right: -30px
      width: calc(100% + 60px)

  .title
    font-size: 30px
    padding-bottom: 5px
  .price
    color: #044eff
    font-size: 20px
    padding-left: 10px
    padding-right: 10px
    border: 1px solid #044eff
    border-radius: 10px
    margin-top: 10px
    margin-bottom: 15px

  .point
    font-weight: 400
    font-size: 16px
    img
      width: 12px
      height: 12px
      margin-right: 5px

  .cta
    margin-top: 15px
    padding-left: 10px
    padding-right: 10px
    padding-top: 5px
    padding-bottom: 5px
    font-size: 16px
    border-radius: 5px
    font-weight: 700
    border: 2px solid rgba(77,77,77)


.description
  margin-top: -10px
  font-size: 20px
  font-weight: 400

.flexWrap
  display: flex
  margin-right: auto
  margin-left: auto
  justify-content: center