@import '../../../../styles/helpers'
.wrapper
  display: flex
  flex-direction: column
  width: 100%
  color: rgba(77,77,77)
  padding: 80px
  padding-top: 0px

.primary
  font-size: 40px
  text-align: center
.secondary
  font-size: 24px
  margin-top: 0px
  font-weight: 400
  text-align: center
  margin-bottom: 20px

.integration
  width: 70%
  margin-right: auto
  margin-left: auto
  img
    width: 100%
    height: 100%
  +t
    width: 90%

:global(#UNIQUE_ID_OF_DIV)
  width: 100% !important
  max-width: 600px
  max-height: 220px
  border-radius: 10px
  margin-left: auto
  margin-right: auto